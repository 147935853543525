<template>
	<section id="tw-view-news-notices" class="tw-content col-12">
		<div class="bg-white">
			<div class="p-4">
				<div class="tw-table-header row">
					<div class="tw-table-search-wrapper col-lg-4 text-end">
                        <input type="search" class="form-control" v-model="querySearch" placeholder="Search news / notices" />
                        <span class="tw-table-header-search-icon"><i class="fas fa-search"></i></span>
                    </div>
                    <div v-if="siteType == 'cms6' && userHasCms6Permission('view_deleted_news_notices')" id="tw-news-notices-view-wrapper" class="col mt-3 mt-lg-0">
                        <div class="d-flex">
                            <div>
                                <button class="tw-light-grey-bg-color tw-default-text-color d-flex align-items-center justify-content-center w-100" :class="{ active: showingTrashed }" @click.prevent.stop="showTrashed">
                                    <span class="d-flex justify-content-center align-items-center">
                                        <i class="fas fa-dumpster"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 mt-3 mt-lg-0">
						<Datepicker class="tw-table-header-filter-date" v-model="filterDate" :format="format" monthPicker placeholder="Filter by Month" />
					</div>
					<div class="col text-end mt-3 mt-lg-0 d-flex">
						<button class="tw-default-bg-color text-white d-block d-lg-flex align-items-center ms-auto justify-content-center px-3 me-auto me-lg-0" v-if="selected" @click.prevent="bulkDelete">
							<i class="fa fa-trash"></i>
							<span class="ps-xl-3 d-inline-flex align-items-center flex-grow-1">Delete {{ selected }} Posts</span>
						</button>
						<button class="tw-red-bg-color text-white d-flex align-items-center ms-auto me-auto me-lg-0" @click="openCreatePostModal">
							<span class="d-inline-flex justify-content-center align-items-center tw-light-red-bg-color me-3">
								<i class="fas fa-plus"></i>
							</span>
							<span class="ps-xl-3 d-inline-flex align-items-center flex-grow-1 pe-5">Create Post</span>
						</button>
					</div>
				</div>
				<div class="table-responsive">
					<table ng-if="!vm.permissions.notices" st-table="vm.results" st-pipe="vm.loadResults" class="tw-table table text-nowrap">
						<thead>
							<tr>
								<th class="tw-table-checkbox-th"></th>
								<th>Picture</th>
								<th st-sort="title">
									<span class="cursor-pointer d-flex align-items-center" @click="setOrder('title')">
										<span class="me-3">Title</span>
										<span v-if="orderBy != 'title'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-up me-2">
												<span class="d-block"></span>
											</span>
											<span class="tw-toggle-angle-down">
												<span class="d-block"></span>
											</span>
										</span>
										<span v-if="orderBy == 'title' && order == 'asc'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-up">
												<span class="d-block"></span>
											</span>
										</span>
										<span v-if="orderBy == 'title' && order == 'desc'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-down">
												<span class="d-block"></span>
											</span>
										</span>
									</span>
								</th>
                                <th v-if="siteType == 'cms6' && userHasCms6Permission('edit_news_notice_slug')" st-sort="slug" style="cursor: pointer" @click="setOrder('slug')">
                                    <span class="me-3">Permalink</span>
                                    <span v-if="orderBy != 'slug'" class="d-inline-flex align-items-center justify-content-center">
                                        <span class="tw-toggle-angle-up me-2">
                                            <span class="d-block"></span>
                                        </span>
                                        <span class="tw-toggle-angle-down">
                                            <span class="d-block"></span>
                                        </span>
                                    </span>
                                    <span v-if="orderBy == 'slug' && order == 'asc'" class="d-inline-flex align-items-center justify-content-center">
                                        <span class="tw-toggle-angle-up">
                                            <span class="d-block"></span>
                                        </span>
                                    </span>
                                    <span v-if="orderBy == 'slug' && order == 'desc'" class="d-inline-flex align-items-center justify-content-center">
                                        <span class="tw-toggle-angle-down">
                                            <span class="d-block"></span>
                                        </span>
                                    </span>
                                </th>
								<th st-sort="date">
									<span class="cursor-pointer d-flex align-items-center" @click="setOrder('date')">
										<span class="me-3">Date</span>
										<span v-if="orderBy != 'date'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-up me-2">
												<span class="d-block"></span>
											</span>
											<span class="tw-toggle-angle-down">
												<span class="d-block"></span>
											</span>
										</span>
										<span v-if="orderBy == 'date' && order == 'asc'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-up">
												<span class="d-block"></span>
											</span>
										</span>
										<span v-if="orderBy == 'date' && order == 'desc'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-down">
												<span class="d-block"></span>
											</span>
										</span>
									</span>
								</th>
								<th>Category</th>
								<th>Notifications</th>
								<th></th>
							</tr>
						</thead>

						<tbody class="position-relative">
							<div v-if="!requestResolved" class="tw-table-loading-overlay pt-5 d-flex align-items-start justify-content-center position-absolute top-0 bottom-0 start-0 end-0 bg-white opacity-75">
								<i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
							</div>
						<!-- <tbody v-if="posts && posts.length"> -->
							<tr v-for="(post, index) in posts" :class="post.type == 'tw_alerts' && 'red'" :key="index">
								<td class="tw-checkbox"><input :id="'post' + post.id" type="checkbox" @click="countSelected(index, $event)" value="1" :checked="post.checked"/><label :for="'post' + post.id"></label></td>
								<td class="ps-4">
									<img v-if="post.featured_media || post.thumbnail_url" :src="post.featured_media ? post.featured_media.media_details.sizes.thumbnail.source_url : post.thumbnail_url" :alt="post.post_title || post.title" height="53" width="55">
									<span v-else class="d-flex align-items-center">
										<span v-html="defaultImage"></span> <a v-if="post.type == 'post'" class="tw-red-color ms-md-3 fw-bold" href="#" @click.stop.prevent="openCreatePostModal($event, post.id)">Click to add</a>
									</span>
								</td>
								<td>
									<a class="tw-table-title-column" href="" v-if="post.type == 'post'" @click.stop.prevent="!showingTrashed && openCreatePostModal($event, post.id)" v-html="sliceString(post.title, 70)" data-bs-html="true" data-bs-toggle="tooltip" data-bs-placement="top" :title="(post.title.rendered || post.title.rendered === '') ? post.title.rendered : post.title"></a> <small v-if="post.status != 'publish' && post.type == 'post'" class="bg-info text-white px-1 rounded text-capitalize">{{ post.status }}</small>
									<a class="tw-table-title-column" href="" v-if="post.type != 'post'" @click.prevent="openCreateAlertModal($event, post.id)" v-html="sliceString(post.title, 70)" data-bs-html="true" data-bs-toggle="tooltip" data-bs-placement="top" :title="(post.title.rendered || post.title.rendered === '') ? post.title.rendered : post.title"></a> <small v-if="post.status != 'publish' && post.type != 'post'" class="bg-info text-white px-1 rounded text-capitalize">{{ post.status }}</small>
								</td>
                                <td v-if="siteType == 'cms6' && userHasCms6Permission('edit_news_notice_slug')">
                                    <a v-if="post.slug_prefix" :href="siteUrl + '/' + post.slug_prefix + post.slug" target="_blank">{{ siteUrl + "/" + post.slug_prefix + post.slug }}</a>
                                    <a v-else :href="siteUrl + '/' + post.slug" target="_blank">{{ siteUrl + "/" + post.slug }}</a>
                                </td>
								<td>{{ moment(post.post_date || post.date).format('ll') }}</td>
								<td>
									<div v-if="post.type == 'post'">
										<span v-for="(category, index) in post.categories" :key="index"><span v-html="category.name"></span><span v-if="(index + 1) != post.categories.length">,</span> </span>
									</div>
									<span v-if="post.type == 'tw_alerts'">Emergency Alert</span>
								</td>
								<td><b>{{ post.notifications.slice(0,-14).split(' ')[0] }}</b> {{ post.notifications.slice(0,-14).split(' ')[1] }}</td>
								<!-- <td>{{ //post.meta['tw-notifications-sent'].slice(0,-14) }}</td> -->
								<td v-if="post.type == 'post'" class="text-end column-actions">
									<div v-if="!showingTrashed" class="d-flex justify-content-end">
										<a href="" @click.prevent="removePost(post)" class="d-flex align-items-center"><span class="fa-stack"><i class="tw-light-red-color fa fa-circle fa-stack-2x"></i><i class="fa fa-trash fa-stack-1x fa-inverse"></i></span></a>
										<a href="" @click.stop.prevent="openCreatePostModal($event, post.id)" class="d-flex align-items-center"><span class="fa-stack"><i class="tw-light-red-color fa fa-circle fa-stack-2x"></i><i class="fas fa-pencil-alt fa-stack-1x fa-inverse"></i></span></a>
									</div>
                                    <div v-if="showingTrashed && siteType == 'cms6' && userHasCms6Permission('restore_deleted_news_notices')" class="d-flex justify-content-end">
                                        <!-- if pages view showing trashed -->
                                        <a href="#" class="d-flex align-items-center" @click.prevent="restore(post.id)"
                                            ><span class="fa-stack"><i class="tw-light-green-color fa fa-circle fa-stack-2x"></i><i class="fas fa-trash-restore fa-stack-1x fa-inverse"></i></span
                                        ></a>
                                        <a v-if="userHasCms6Permission('force_delete_news_notices')" href="" @click.prevent="forceRemoveNewsNotice(post)" class="d-flex align-items-center"
                                            ><span class="fa-stack"><i class="tw-dark-grey-color fa fa-circle fa-stack-2x"></i><i class="fa fa-trash fa-stack-1x fa-inverse"></i></span
                                        ></a>
                                    </div>
								</td>
								<td v-if="post.type == 'tw_alerts'" class="text-end column-actions">
									<div class="d-flex justify-content-end">
										<a href="" @click.prevent="removeAlert(post)" class="d-flex align-items-center"><span class="fa-stack"><i class="tw-light-red-color fa fa-circle fa-stack-2x"></i><i class="fa fa-trash fa-stack-1x fa-inverse"></i></span></a>
										<a href="" @click.prevent="openCreateAlertModal($event, post.id)" class="d-flex align-items-center"><span class="fa-stack"><i class="tw-light-red-color fa fa-circle fa-stack-2x"></i><i class="fas fa-pencil-alt fa-stack-1x fa-inverse"></i></span></a>
									</div>
								</td>
							</tr>
						</tbody>

						<!-- <tbody v-if="!requestResolved && (!posts || !posts.length)">
							<tr>
								<td colspan="6" class="text-center">Loading <i class="fa fa-spinner fa-spin"></i></td>
							</tr>
						</tbody> -->

						<tbody v-if="requestResolved && (!posts || !posts.length)">
							<tr>
								<td colspan="6" class="text-center">Nothing to show, no results found.</td>
							</tr>
						</tbody>

						<tfoot v-if="posts.length">
							<tr>
								<td colspan="3">
									Rows per page
									<select v-model="perPage" class="border">
										<option :value="5">5</option>
										<option :value="10">10</option>
										<option :value="20">20</option>
									</select>
								</td>
								<td colspan="4" class="tw-table-pagination text-end">

									<Pagination
										:totalItems="numberOfPosts"
										:currentPage="page"
										:perPage="perPage"
										@pageChanged="setPage"
										:goButton="false"
										styled="centered"
										borderActiveColor=""
										borderTextActiveColor="text-white"
									/>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>
		<CreatePostModal ref="CreatePostModal" :editing="editing" :editingPostId="editingPostId" @postSaved="handlePostSaved" @modalHidden="handleModalHidden" @slugUpdated="handleSlugUpdated" />
	</section>
</template>

<script>
	import siteMixin from '@/mixins/siteMixin';
	import Pagination from '@/components/pagination/Pagination'
	import CreatePostModal from '@/components/modals/CreatePostModal';
	import helperMixin from '@/mixins/helperMixin';
	import wordpressMixin from '@/mixins/wordpressMixin';
	import cms6Mixin from '@/mixins/cms6Mixin';
    import { Tooltip } from "bootstrap";

	let site = localStorage.getObject('site');

	let mixins;

	if (site.type == 'wordpress') {
		mixins = [wordpressMixin,siteMixin,helperMixin];
	} else if(site.type == 'cms6') {
		mixins = [cms6Mixin,siteMixin,helperMixin];
	}
	export default {
		name: 'NewsNotices',
		components: {
			Pagination,
			CreatePostModal,
		},
		data: () => ({
			posts: [],
			numberOfShowedPosts: 0,
			numberOfPosts: 0,
			numberOfPages: 0,
			querySearch: '',
			filterDate: null,
			format: 'MMMM Y',
			requestResolved: false,
			page: 1,
			perPage: 10,
			order: 'desc',
			orderBy: 'date',
			selected: 0,
			// tableColumns: [{title:'Picture'},{title:'Title'},{title:'Category'},{title:'Date'},{title:'Notifications'}],
			defaultImage: '<div class="tw-defaul-featured-img"><i class="fas fa-image"></i></div>',
			// defaultImage: 'https://via.placeholder.com/35x35?text=Photo',
			editing: false,
			editingPostId: null,
			alertEditing: false,
			editingAlertId: null,
			siteSupportsAlerts: false,
			site_type: null,
            showingTrashed: false,
		}),
		mounted() {
			this.site_type = this.getSiteType();
			if (this.$route.hash) {
				this.$refs.CreatePostModal.showModal(true);
			}
			this.setPosts();
			this.siteSupportsAlerts = this.supportsAlerts();
		},
        updated() {
            let tooltopElements = document.querySelectorAll('[data-bs-toggle="tooltip"]');

            tooltopElements.forEach(element => {

                let tooltip = Tooltip.getInstance(element);

                if (tooltip) {
                    tooltip.dispose();
                }

                if (!Tooltip.getInstance(element)) {
                    new Tooltip(element)
                }
            });
        },
		watch: {
			perPage: function () {
				// this.fullName = val + ' ' + this.lastName
				// this.posts = [];
				this.page = 1;
				this.setPosts();
			},
			page: function () {
				// this.fullName = val + ' ' + this.lastName
				// this.posts = [];
				this.setPosts();
			},
			orderBy: function () {
				// this.fullName = val + ' ' + this.lastName
				// this.posts = [];
				this.setPosts();
			},
			order: function () {
				// this.fullName = val + ' ' + this.lastName
				// this.posts = [];
				this.setPosts();
			},
			querySearch: function () {
				// this.fullName = val + ' ' + this.lastName
				// this.posts = [];
				this.setPosts();
			},
			filterDate: function () {
				// this.fullName = val + ' ' + this.lastName
				// this.posts = [];
				this.setPosts();
			},
		},
		methods: {
			async setPosts(showTrashed = false) {
				this.requestResolved = false;

                if (this.siteType == "cms6" && this.showingTrashed) {
                    showTrashed = true;
                }

				// this.posts.map( (post) => { post.selected == false } )
				// tw-table-checkbox
				this.$el.querySelectorAll("td.tw-checkbox input").forEach( function(checkbox){
					checkbox.checked = false;
				});
				this.selected = 0;
				// this.posts.forEach(function(post){ delete post.selected });
				// if ('/wp/v2/site-news' in this.getSiteRoutes()) {
					let res = await this.getNews(this.page, this.perPage, this.order, this.orderBy, this.querySearch, this.filterDate, showTrashed);
					if (res && res.status === 200) {
						this.requestResolved = true;
						this.posts = res.data.post_data || [];
						this.numberOfShowedPosts = this.posts.length;
						this.numberOfPosts = res.data.posts;
						this.numberOfPages = res.data.pages;
					}
				// } else {
				// 	let res = await this.getPosts();
				// 	if (res && res.status === 200) {
				// 		this.requestResolved = true;
				// 		this.posts = res.data || [];
				// 		this.numberOfShowedPosts = this.posts.length;
				// 		this.numberOfPosts = res.data.posts;
				// 		this.numberOfPages = res.data.pages;
				// 		// console.log(this.requestResolved)
				// 		console.log(res.data)
				// 	}
				// }
			},
			setPage(n) {
				this.page = n;
			},
			async removePost(post) {
				this.$swal({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    icon: 'warning',
                    text: "Are you sure you want to delete this notice?",
                    confirmButtonText: 'Yes, delete it!',
                    showLoaderOnConfirm: true,
					preConfirm: async () => {
						try {
							let res;
							if (this.site_type == 'wordpress') {
								res = await this.deletePost(post.id);
							} else if(this.site_type == 'cms6') {
								res = await this.deleteNewsNotice(post.id);
							}

							return res;

						} catch(error) {
							this.$swal.showValidationMessage(
								`Request failed: ${error}`
							)
						}
					},
					allowOutsideClick: () => !this.$swal.isLoading()
                }).then(async (result) => {
					if (result.isConfirmed) {

						this.$swal(
							'Deleted!',
							"Post has been deleted!",
							'success',
						)
						.then(() => {
							this.requestResolved = false;

							this.setPosts();
						});
					}
                });
			},
			async removeAlert(post) {

				this.$swal({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    icon: 'warning',
                    text: "Are you sure you want to delete this alert?",
                    confirmButtonText: 'Yes, delete it!'
                }).then(async (result) => {

					if (result.isConfirmed) {

						this.requestResolved = false;

						let res = await this.deleteAlert(post.id);

						this.setPosts();

						if (res.status && res.status == 200) {
							this.$swal(
								'Deleted!',
								"Alert has been deleted!",
								'success',
							);
						} else {
							this.$swal(
								'Failure!',
								"Alert has NOT been deleted!",
								'danger',
							);
						}
					}
                });
			},
			async bulkDelete () {


				this.$swal({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    icon: 'warning',
                    text: "Are you sure you want to delete all selected news and notices?",
                    confirmButtonText: 'Yes, delete!'
                }).then(async (result) => {

					if (result.isConfirmed) {
						this.requestResolved = false;

						let self = this;
						let posts = this.posts;

						await Promise.all(posts.map( async function(post){

							if (post.selected) {
								if (post.type == 'tw_alerts') {
									await self.deleteAlert(post.id);
								} else {

									if (self.site_type == 'wordpress') {
										await self.deletePost(post.id);
									} else if(self.site_type == 'cms6') {
										await self.deleteNewsNotice(post.id);
									}
								}
							}

						}));

						this.setPosts();

						this.$swal(
							'Deleted!',
							"Notices have been deleted!",
							'success',
						);

					}
                });
			},
			setOrder(orderBy) {
				if (this.orderBy != orderBy) {
					this.order = 'desc';
				} else if (this.orderBy == orderBy && this.order == 'desc') {
					this.order = 'asc';
				} else if (this.orderBy == orderBy && this.order == 'asc') {
					this.order = 'desc';
				}
				this.orderBy = orderBy;
			},
			countSelected(index, event) {

				if (event.target.checked) {
					this.posts[index].selected = true;
				} else {
					this.posts[index].selected = false;
				}

				this.selected = this.posts.filter(function(post) {return post.selected}).length;
			},
			openCreatePostModal(event, postId = null) {
				if (postId) {this.editing = true;}
				this.editingPostId = postId;
				this.$refs.CreatePostModal.showModal(true);
			},
			openCreateAlertModal(event, postId = null) {
				if (postId) {this.alertEditing = true;}
				this.editingAlertId = postId;
				this.$refs.CreateAlertModal.showModal(true);
			},
			handleModalHidden() {
				this.editing = false;
				this.editingPostId = null;
			},
            showTrashed() {
                this.showingTrashed = !this.showingTrashed;

                this.setPosts();
            },
            async restore(id) {
                this.requestResolved = false;
                await this.newsNoticeRestore(id);

                this.setPosts();
            },
            handleSlugUpdated() {
                this.setPosts();
            },
            async forceRemoveNewsNotice(post) {
                this.$swal({
                    title: "Are you sure?",
                    showCancelButton: true,
                    icon: "warning",
                    text: "There is no revert. Are you sure you want to delete this post from trash?",
                    confirmButtonText: "Yes, delete the post permanently!",
                    showLoaderOnConfirm: true,
                    preConfirm: async () => {
                        try {
                            let res = await this.forceDeleteNewsNotice(post.id);

                            return res;
                        } catch (error) {
                            this.$swal.showValidationMessage(`Request failed: ${error}`);
                        }
                    },
                    allowOutsideClick: () => !this.$swal.isLoading(),
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.$swal("Deleted!", "Post has been deleted permanently!", "success").then(() => {
                            this.requestResolved = false;

                            this.setPosts();
                        });
                    }
                });
            },
			handleAlertModalHidden() {
				this.alertEditing = false;
				this.editingAlertId = null;
			},
			handlePostSaved(action) {
				this.$swal(
					`Post ${action}!`,
					`Post ${action} successfully!`,
					'success',
				)
				.then(() => {

					this.requestResolved = false;
					// this.posts = [];
					this.setPosts();
				});
			}
		},
        computed: {
            siteType() {
                return this.getSiteType();
            },
            siteUrl() {
                return this.getSiteUrl();
            },
        },
		mixins: mixins
    }
</script>
<style scoped>
#tw-news-notices-view-wrapper button.active {
    color: #fff !important;
    background: var(--tw-red) !important;
}
</style>
